/**
 * Retrieves the value of a cookie by its name.
 * @param {string} name - The name of the cookie.
 * @returns {string | undefined} The value of the cookie, or undefined if not found.
 */
export function getCookie(name) {
    let matches = document.cookie.match(new RegExp('(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
/**
 * Retrieves the user ID from the cookies.
 * @returns The user ID if found, otherwise undefined.
 */
export function getUserIdFromCookies() {
    return getCookie('userId');
}
/**
 * Retrieves the user ID from a span element in the DOM.
 * @returns The user ID if found, otherwise undefined.
 */
export function getUserIdFromSpan() {
    const spanElement = document.getElementById('get-user-id');
    return (spanElement === null || spanElement === void 0 ? void 0 : spanElement.getAttribute('data-userid')) || undefined;
}
/**
 * Enriches the configuration object with the user ID from cookies.
 * @param {Config} config - The configuration object to enrich.
 */
export function enrichConfigWithUserId(config) {
    const userId = getCookie('userId');
    if (userId) {
        // Set the user_id property on the config object
        config.user_id = userId;
    }
}
/**
 * Attempts to set the user ID by periodically checking for the user ID in cookies or span.
 * If the user ID is found, it sets the user ID in the config object and calls the callback function.
 * If the user ID is not found after the specified number of attempts, it logs a warning message.
 * @param config - The configuration object.
 * @param callback - The callback function to be called when the user ID is found.
 * @param attempts - The maximum number of attempts to find the user ID. Default is 3.
 * @param interval - The interval (in milliseconds) between each attempt. Default is 1000ms (1 second).
 */
export function attemptToSetUserId(config, callback, attempts = 3, interval = 500) {
    let currentAttempt = 0;
    const intervalId = setInterval(() => {
        const userId = getUserIdFromCookies() || getUserIdFromSpan();
        if (userId || currentAttempt >= attempts) {
            clearInterval(intervalId);
            if (userId) {
                config.user_id = userId;
                callback();
            }
            else {
                console.warn('User ID could not be found in cookies.');
            }
        }
        currentAttempt++;
    }, interval);
}
/**
 * Loads a script dynamically by creating a script element and appending it to the specified target.
 * @param src - The URL of the script to load.
 * @param target - The target element where the script should be appended. Default is 'head'.
 * @param callback - An optional callback function to be executed when the script is loaded.
 */
export function loadScript(src, target = 'head', callback) {
    // Check if the script is already loaded
    const existingScript = Array.from(document.getElementsByTagName('script')).find((script) => script.src === src);
    if (existingScript) {
        if (callback)
            callback();
        return; // If the script is already loaded, do nothing
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = () => {
        if (callback)
            callback();
    };
    script.onerror = () => {
        console.error(`Failed to load script: ${src}`);
    };
    const appendScript = () => {
        if (target === 'head') {
            document.head.appendChild(script);
        }
        else {
            document.body.appendChild(script);
        }
    };
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', appendScript);
    }
    else {
        appendScript();
    }
}
