/**
 * The application configuration with specified integrations and groups.
 * @type {Config}
 */
export const config_staging_kr = {
    stand: 'staging',
    host: 'gfn-test.co.kr',
    integrations: {
        log_sink: {
            dataset: 'https://asia-northeast3-gfn-co-kr-data.cloudfunctions.net/log_sink?',
        },
        gtm: {
            gtm_id: '',
        },
        gtag: {
            tag_id: 'G-KWE6QXH2K4',
        },
        facebook: {
            id: '1416145292357610',
        },
        naver: {
            nid: 's_52eb85bbff43',
        },
        kakaoKeywordAds: {
            id: '8392526124968163116',
        },
        kakaoMomentum: {
            id: '549113001161113097',
        },
    },
    groups: {
        default: ['none'],
    },
    widgets: {},
};
