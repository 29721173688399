var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { config } from './config';
import { getUserIdFromCookies, getUserIdFromSpan } from './utils';
/**
 * Sends a ping request to the server.
 * @param {string} msg - The message to include in the ping request.
 * @returns {Promise<void>} - A promise that resolves when the ping request is sent.
 */
export const ping = (msg, anydata) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // Get the stand from the config
        const stand = config.stand;
        // Get the dataset from the config
        const dataset = config.integrations.log_sink.dataset;
        // Generate a cache buster value
        const cacheBuster = Date.now().toString();
        // Get the userId from the span or cookies
        const userId = getUserIdFromSpan() || getUserIdFromCookies();
        // Get the current URL and encode it
        const currentUrl = encodeURIComponent(window.location.href);
        // Create the query parameters
        const params = new URLSearchParams({
            stand: stand,
            msg: msg,
            userId: userId !== null && userId !== void 0 ? userId : '',
            anydata: anydata !== null && anydata !== void 0 ? anydata : '',
            url: currentUrl,
            cb: cacheBuster,
        });
        // Construct the query string
        const query = `${dataset}${params.toString()}`;
        // Log the ping request
        // console.log('ping', query);
        // Send the ping request
        yield fetch(query, { cache: 'no-cache' });
    }
    catch (error) {
        console.error('Error sending ping:', error);
    }
});
