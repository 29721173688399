import { config, isDev } from '../config';
import { ping } from '../ping';
import { event_schema } from './schema';
import { integrations } from '../integrations';
/**
 * Sends an event to integrations.
 * @param name - The name of the event.
 * @param properties - The properties associated with the event.
 * @param specificIntegrations - The specific integrations to send the event to.
 */
function sendEvent(name, properties = {}, specificIntegrations = []) {
    // Determine which integrations to send the event to
    const integrationsToSend = specificIntegrations.length > 0
        ? specificIntegrations
        : Object.keys(integrations);
    // Set the maximum number of attempts to send the event
    const max_attempts = 3;
    // Set the delay (in milliseconds) between retry attempts
    const retry_delay_ms = 1000;
    /**
     * Checks if the event should be sent to the integration.
     * @param groups - The groups associated with the event.
     * @param defaultGroups - The default groups from the config.
     * @param key - The integration key.
     * @returns True if the event should be sent to the integration, false otherwise.
     */
    const shouldSendToIntegration = (groups, defaultGroups, key) => {
        return groups ? groups.includes(key) : defaultGroups.includes(key);
    };
    /**
     * Handles errors during event sending.
     * @param message - The error message.
     * @param error - The error object.
     */
    const handleError = (message, error) => {
        if (isDev) {
            console.error(message, error || '');
        }
    };
    /**
     * Sends the event to the integration.
     * @param integration - The integration object.
     * @param key - The integration key.
     * @param attempt - The current attempt number.
     */
    const sendEventToIntegration = (integration, key, attempt = 0) => {
        var _a;
        if (integration.isLoaded()) {
            const groups = (_a = event_schema[name]) === null || _a === void 0 ? void 0 : _a.groups;
            const defaultGroups = config.groups.default;
            if (specificIntegrations.length === 0 &&
                !shouldSendToIntegration(groups, defaultGroups, key)) {
                return;
            }
            if (isDev) {
                console.log(`Event "${name}" is attempting to be sent to ${key}.`);
            }
            integration.sendEvent(name, properties);
            const anydata = name === 'dbs_click' ? properties.dbs : undefined;
            ping(`${name}-${key}-success`, anydata);
        }
        else if (attempt < max_attempts) {
            setTimeout(() => {
                sendEventToIntegration(integration, key, attempt + 1);
            }, retry_delay_ms);
        }
        else {
            handleError(`Integration ${key} is not loaded yet after ${max_attempts} attempts. ${name} not delivered.`);
            ping(`${name}-${key}-not_loaded`);
        }
    };
    integrationsToSend.forEach((key) => {
        const integration = integrations[key];
        if (integration) {
            try {
                sendEventToIntegration(integration, key);
            }
            catch (error) {
                handleError(`Error sending event "${name}" to ${key}:`, error);
                ping(`${name}-${key}-error`);
            }
        }
        else {
            handleError(`Integration ${key} not found for event "${name}".`);
        }
    });
}
export { sendEvent };
