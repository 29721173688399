import { loadScript } from '../utils';
import { event_schema } from '../events';
export const createIntentIntegration = () => {
    const load = () => {
        loadScript('https://cdn1.intent.ai/analytics/intent.js');
    };
    const sendEvent = (eventName, eventProperties) => {
        var _a, _b;
        const intentEventName = (_b = (_a = event_schema[eventName]) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.intent;
        if (!intentEventName) {
            return;
        }
        window.intentData = window.intentData || [];
        intentData.push([intentEventName]);
    };
    const isLoaded = () => {
        return (typeof window.intentData === 'object' || typeof intentData === 'object');
    };
    return {
        load,
        sendEvent,
        isLoaded,
    };
};
