/**
 * The application configuration with specified integrations and groups.
 * @type {Config}
 */
export const config_production_am = {
    stand: 'production',
    host: 'gfn.am',
    data: {},
    integrations: {
        log_sink: {
            dataset: 'https://europe-west3-gfn-am-data.cloudfunctions.net/log_sink?',
        },
        gtm: {
            gtm_id: 'GTM-KN93TXH',
        },
        gtag: {
            tag_id: 'G-3TP2LP3ZRR',
        },
        facebook: {
            id: '576252714026979',
        },
        yandex: {
            ym_id: 92050288,
        },
        intent: {},
    },
    groups: {
        default: ['yandex'],
    },
    widgets: {
        chatra: {
            chatra_id: '5xDq5jWkEudTzQvvd',
            display: ['landing', 'lk'],
        },
    },
};
