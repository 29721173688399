export const createYandexIntegration = (config) => {
    var _a, _b;
    const ym_id = (_b = (_a = config === null || config === void 0 ? void 0 : config.integrations) === null || _a === void 0 ? void 0 : _a.yandex) === null || _b === void 0 ? void 0 : _b.ym_id;
    const load = () => {
        const ymInit = document.createElement('script');
        ymInit.type = 'text/javascript';
        ymInit.async = true;
        ymInit.text = `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();
    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  
    ym(${ym_id}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
    });`;
        var ymNoscript = document.createElement('noscript');
        ymNoscript.innerHTML = `
      <div><img src="https://mc.yandex.ru/watch/${ym_id}" style="position:absolute; left:-9999px;" alt="" /></div>
    `;
        if (document.body) {
            document.body.appendChild(ymInit);
            document.body.appendChild(ymNoscript);
        }
        else {
            document.addEventListener('DOMContentLoaded', () => {
                document.body.appendChild(ymInit);
                document.body.appendChild(ymNoscript);
            });
        }
    };
    const sendEvent = (eventName, eventProperties) => {
        window.ym(ym_id, 'reachGoal', eventName, eventProperties);
    };
    const isLoaded = () => {
        return typeof window.ym === 'function';
    };
    return {
        load,
        sendEvent,
        isLoaded,
    };
};
