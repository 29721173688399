/**
 * Event schema defines the structure of events that can be tracked.
 *
 * @typedef {Object} EventSchema
 *
 * @property {Object} reg_visit - Event for user registration visit.
 * @property {Object} reg_attempt - Event for user registration attempt.
 * @property {Object} sign_up - Event for user registration success.
 * @property {Object} login - Event for user web login.
 * @property {Object} login_app - Event for user app login.
 * @property {Object} card_delete - Event for user card deletion.
 * @property {Object} purchase - Event for user purchase completion.
 * @property {Object} add_to_cart - Event for user purchase attempt.
 * @property {Object} activate_code_click - Event for activate code button click.
 * @property {Object} activate_code_error - Event for activate code error.
 * @property {Object} activate_code_success - Event for activate code success.
 * @property {Object} landing_download - Event for download button click on landing page.
 * @property {Object} landing_gopremium_connect_click - Event for GoPremium connect button click on landing page.
 * @property {Object} landing_subscription_click - Event for subscriptions button click on landing page.
 */
const event_schema = {
    reg_visit: {
        trigger: {},
        description: 'User registration visit',
    },
    reg_attempt: {
        trigger: {
            type: 'click',
            selector: '[data-dbs="kc-createUser-registerBtnDBS"]',
        },
        description: 'User registration attempt',
    },
    sign_up: {
        trigger: {},
        args: {
            facebook: 'CompleteRegistration',
            naver: 'sign_up',
            intent: 'sign_up',
            kakaoKeywordAds: 'completeRegistration',
            kakaoMomentum: 'completeRegistration',
        },
        groups: ['yandex', 'intent', 'naver', 'kakaoKeywordAds', 'kakaoMomentum'],
        description: 'User registration success',
    },
    login: {
        trigger: {},
        args: {
            kakaoKeywordAds: 'login',
            kakaoMomentum: 'login',
        },
        description: 'User login web',
    },
    login_app: {
        trigger: {},
        args: {
            intent: 'login_in_app',
        },
        description: 'User login app',
    },
    card_delete: {
        trigger: {},
        description: 'User deletes a card',
    },
    purchase: {
        trigger: {},
        args: {
            facebook: 'Purchase',
            naver: 'purchase',
            intent: 'purchase',
            kakaoKeywordAds: 'purchase',
            kakaoMomentum: 'purchase',
        },
        groups: ['yandex', 'intent', 'naver', 'kakaoKeywordAds', 'kakaoMomentum'],
        description: 'User completes purchase',
    },
    add_to_cart: {
        trigger: {},
        description: 'User attempts purchase',
    },
    activate_code_click: {
        trigger: {},
        description: 'User clicks on the activate code button',
    },
    activate_code_error: {
        trigger: {},
        description: 'User fails to activate a code',
    },
    activate_code_success: {
        trigger: {},
        description: 'User successfully activates a code',
    },
    landing_download: {
        trigger: {
            type: 'click',
            selector: '[data-dbs^="lp-download"]',
        },
        groups: ['gtag', 'yandex'],
        description: 'User clicks on the download page download button',
    },
    landing_gopremium_connect_click: {
        trigger: {
            type: 'click',
            selector: '[data-dbs^="lp-premium-hero-btnBuy"], [data-dbs^="lp-premium-cta-btnBuy"]',
        },
        description: 'User clicks on the gopremium page connect buttons',
    },
    landing_subscription_click: {
        trigger: {
            type: 'click',
            selector: '[data-dbs^="lp-subscriptions-btnBuy"]',
        },
        description: 'User clicks on the subscriptions card button',
    },
    dbs_click: {
        trigger: {
            type: 'click',
            selector: '[data-dbs], [data-dbs] *',
        },
        groups: ['gtag'],
        description: 'User clicks on a DBS element',
    },
    landing_view: {
        trigger: {
            type: 'pageview',
            target: ['landing'],
        },
        args: {
            intent: 'landing_view',
        },
        groups: ['intent'],
        description: 'LandingView enent on landing pages.',
    },
    page_view: {
        trigger: {
            type: 'pageview',
            target: ['landing', 'lk', 'keycloak'],
        },
        args: {
            naver: 'pageview',
            kakaoKeywordAds: 'pageView',
            kakaoMomentum: 'pageView',
            facebook: 'PageView',
        },
        groups: ['kakaoKeywordAds', 'kakaoMomentum'],
        description: 'PageView event on all pages.',
    },
    user_data: {
        trigger: {
            type: 'pageview',
            target: ['keycloak'],
        },
        groups: ['gtag'],
        description: 'User data event on keycloak pages.',
    },
};
export { event_schema };
