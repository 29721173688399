export const createChatraWidget = (chatra_id) => {
    const load = () => {
        (function (d, w, c) {
            w.ChatraID = chatra_id;
            const s = d.createElement('script');
            // @ts-ignore
            w[c] =
                // @ts-ignore
                w[c] ||
                    function () {
                        // @ts-ignore
                        (w[c].q = w[c].q || []).push(arguments);
                    };
            s.async = true;
            s.src = 'https://call.chatra.io/chatra.js';
            if (d.head)
                d.head.appendChild(s);
        })(document, window, 'Chatra');
        let chatraLang = 'en';
        const pathArr = document.location.pathname.split('/');
        if (pathArr.includes('ru')) {
            chatraLang = 'ru';
        }
        window.ChatraSetup = {
            language: chatraLang,
        };
        function passingDataToChatra() {
            if (typeof gfnStorage != 'undefined') {
                gfnStorage.loadUserData(function () {
                    const data = gfnStorage.getUserData();
                    Chatra('setIntegrationData', {
                        name: data.username,
                        email: data.email,
                        Plan: data.subscription,
                        UID: data.userId,
                    });
                });
            }
        }
        setTimeout(passingDataToChatra, 1000);
    };
    const isLoaded = () => {
        return typeof Chatra !== 'undefined';
    };
    return {
        load,
        isLoaded,
    };
};
