import { event_schema } from '../events';
import { loadScript } from '../utils';
function transformEventProperties(eventProperties) {
    if (!eventProperties.transaction_id || !eventProperties.items) {
        throw new Error('Invalid event properties');
    }
    return {
        type: 'purchase',
        id: eventProperties.transaction_id,
        items: eventProperties.items.map((item) => ({
            id: item.item_id,
            name: item.item_name,
            category: item.category || '',
            quantity: item.quantity,
            payAmount: item.price,
            option: `period:${item.period || ''}`.trim(),
        })),
        value: eventProperties.value,
    };
}
export const createNaverIntegration = (config) => {
    var _a, _b;
    const nid = (_b = (_a = config === null || config === void 0 ? void 0 : config.integrations) === null || _a === void 0 ? void 0 : _a.naver) === null || _b === void 0 ? void 0 : _b.nid;
    const host = config === null || config === void 0 ? void 0 : config.host;
    const load = () => {
        loadScript('https://wcs.naver.net/wcslog.js', 'head', () => {
            const naverConfigScipt = document.createElement('script');
            naverConfigScipt.innerHTML = `
      if (!wcs_add) var wcs_add = {};
      wcs_add['wa'] = '${nid}';
      if (!_nasa) var _nasa = {};
      if (window.wcs) {
        wcs.inflow('${host}');
        wcs_do(_nasa);
      }
      `;
            document.head.appendChild(naverConfigScipt);
        });
    };
    const sendEvent = (eventName, eventProperties) => {
        var _a, _b;
        const naverEventName = (_b = (_a = event_schema[eventName]) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.naver;
        if (!naverEventName) {
            return;
        }
        if (eventName === 'page_view') {
            wcs.event('send', naverEventName);
        }
        let _conv = {};
        if (eventName === 'purchase') {
            const transformedProperties = transformEventProperties(eventProperties);
            _conv = Object.assign(Object.assign({}, transformedProperties), { type: naverEventName });
        }
        else {
            //@ts-ignore
            _conv.type = naverEventName;
        }
        wcs.trans(_conv);
    };
    const isLoaded = () => {
        return typeof wcs === 'object';
    };
    return {
        load,
        sendEvent,
        isLoaded,
    };
};
