import { event_schema } from '../events';
import { getCookie, getUserIdFromCookies, getUserIdFromSpan, loadScript, } from '../utils';
function getYaClientID(ymID) {
    window.ym(ymID, 'getClientID', function (clientID) {
        window.gtag('set', 'user_properties', {
            yaID: clientID,
        });
        window.gtag('event', 'send_yaID');
    });
}
function onYmLoad(ymID) {
    if (window.ym) {
        getYaClientID(ymID);
    }
}
export const createGtagIntegration = (config) => {
    var _a, _b, _c;
    const stand = config.stand;
    const debug_mode = stand === 'staging';
    const tag_id = (_b = (_a = config === null || config === void 0 ? void 0 : config.integrations) === null || _a === void 0 ? void 0 : _a.gtag) === null || _b === void 0 ? void 0 : _b.tag_id;
    const ym_id = (_c = config.integrations.yandex) === null || _c === void 0 ? void 0 : _c.ym_id;
    const user_id = getUserIdFromSpan() || getUserIdFromCookies();
    const fbpCookie = getCookie('_fbp');
    const load = () => {
        loadScript(`https://www.googletagmanager.com/gtag/js?id=${tag_id}`);
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        const config_args = {
            debug_mode: debug_mode,
        };
        if (user_id) {
            config_args.user_id = user_id;
        }
        // gtag config
        window.gtag('config', tag_id, config_args);
        // try to set user_id. If user_id is not set, try to set it in the next 5 seconds
        // userIdentityChange(user_id);
        if (fbpCookie) {
            window.gtag('set', 'user_properties', {
                fbp: fbpCookie,
            });
        }
        if (ym_id) {
            if (window.ym) {
                getYaClientID(ym_id);
            }
            else {
                window.addEventListener('load', () => onYmLoad(ym_id));
            }
        }
    };
    const sendEvent = (eventName, eventProperties) => {
        var _a, _b;
        const gtagEventName = ((_b = (_a = event_schema[eventName]) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.gtag) || eventName;
        window.gtag('event', gtagEventName, eventProperties);
    };
    const isLoaded = () => {
        return typeof window.gtag === 'function';
    };
    return {
        load,
        sendEvent,
        isLoaded,
    };
};
