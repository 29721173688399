import { config, isDev } from './config';
import { useSitePart } from './hooks';
import { attemptToSetUserId, enrichConfigWithUserId, } from './utils';
import { event_schema, initEventListeners, sendEvent } from './events';
import { initIntegrations } from './integrations';
import { initWidgets } from './widgets';
(function (window) {
    // Check if the eventHelper.js library has already been loaded
    if (window.eventHelper) {
        if (isDev) {
            console.warn('eventHelper.js library has already been loaded.');
        }
        return;
    }
    // Check if configuration is available
    if (!config) {
        console.error('No configuration found for this domain');
        return;
    }
    // hooks
    const { sitePart } = useSitePart(config);
    enrichConfigWithUserId(config);
    function loadWidgets() {
        initWidgets(config, sitePart);
    }
    initIntegrations(config);
    function loadInitEventListeners() {
        initEventListeners(event_schema, sendEvent, sitePart);
    }
    window.eventHelper = {
        config,
        sendEvent,
        loadInitEventListeners,
        loadWidgets,
    };
})(window);
const testBtn = document.getElementById('testbtn');
testBtn === null || testBtn === void 0 ? void 0 : testBtn.addEventListener('click', () => {
    window.eventHelper.sendEvent('test', {}, ['gtag']);
});
const purchaseBtn = document.querySelector('[data-dbs="lp-purchase"]');
purchaseBtn === null || purchaseBtn === void 0 ? void 0 : purchaseBtn.addEventListener('click', () => {
    window.eventHelper.sendEvent('purchase', {
        transaction_id: 'T_12345_1',
        currency: 'USD',
        value: 30.03,
        items: [
            {
                item_id: `item_12345`,
                item_name: 'PREMIUM SUBSCRIPTION',
                price: 30.03,
                quantity: 1,
                period: 'P30D',
            },
        ],
    });
});
document.addEventListener('DOMContentLoaded', () => {
    // try add user_id to config
    attemptToSetUserId(window.eventHelper.config, () => { });
    // init event listeners
    window.eventHelper.loadInitEventListeners();
    // init widgets
    window.eventHelper.loadWidgets();
    // Send test event
    // eventHelper.sendEvent('test', 'test_value', ['intent']);
    // eventHelper.sendEvent('test_event', { test_prop: 'test_value' });
    /* setTimeout(() => {
      window.eventHelper.sendEvent('user_test', { external_id: 'eid_test' }, [
        'gtag',
        'facebook',
      ]);
    }, 5000); */
});
window.addEventListener('userAuthenticated', (event) => {
    const customEvent = event;
    const user_id = customEvent.detail.user_id;
    window.gtag('set', { user_id: user_id });
    // window.eventHelper.sendEvent('user_identity_retrieved', {}, ['gtag']);
    window.eventHelper.sendEvent('user_identity_retrieved', { external_id: user_id }, ['gtag', 'facebook']);
});
window.addEventListener('load', () => {
    // try add user_id to config
    if (!config.user_id) {
        attemptToSetUserId(window.eventHelper.config, () => { });
    }
});
window.addEventListener('beforeunload', () => {
    // try add user_id to config
    if (!config.user_id) {
        attemptToSetUserId(window.eventHelper.config, () => { });
    }
});
