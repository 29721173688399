import { event_schema } from '../events';
import { getUserIdFromCookies, getUserIdFromSpan } from '../utils';
export const createFacebookIntegration = (config) => {
    var _a, _b;
    const id = (_b = (_a = config === null || config === void 0 ? void 0 : config.integrations) === null || _a === void 0 ? void 0 : _a.facebook) === null || _b === void 0 ? void 0 : _b.id;
    const load = () => {
        const script = document.createElement('script');
        const user_id = getUserIdFromSpan() || getUserIdFromCookies();
        let scriptContent = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');`;
        scriptContent += `fbq('init', '${id}'`;
        if (user_id) {
            scriptContent += `, { 'external_id': '${user_id}' }`;
        }
        scriptContent += `);`;
        scriptContent += `fbq('track', 'PageView');`;
        script.text = scriptContent;
        document.head.appendChild(script);
        const noscript = document.createElement('noscript');
        const img = document.createElement('img');
        img.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`;
        img.width = 1;
        img.height = 1;
        img.style.display = 'none';
        noscript.appendChild(img);
        if (document.body) {
            document.body.appendChild(noscript);
        }
        else {
            document.addEventListener('DOMContentLoaded', () => {
                document.body.appendChild(noscript);
            });
        }
    };
    const sendEvent = (eventName, eventProperties) => {
        var _a, _b;
        const facebookEventName = ((_b = (_a = event_schema[eventName]) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.facebook) || eventName;
        window.fbq('track', facebookEventName, eventProperties);
    };
    const isLoaded = () => {
        return typeof window.fbq === 'function';
    };
    return {
        load,
        sendEvent,
        isLoaded,
    };
};
