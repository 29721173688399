import { createGtagIntegration } from './gtag';
import { createFacebookIntegration } from './facebook';
import { createYandexIntegration } from './yandex';
import { createNaverIntegration } from './naver';
import { createIntentIntegration } from './intent';
import { createKakaoKeywordAdsIntegration } from './kakaoKeywordAds';
import { createKakaoMomentumIntegration } from './kakaoMomentum';
const integrations = {};
function addIntegration(name, integration) {
    integrations[name] = integration;
}
function initIntegrations(config) {
    if (config.integrations.gtag) {
        addIntegration('gtag', createGtagIntegration(config));
    }
    if (config.integrations.facebook) {
        addIntegration('facebook', createFacebookIntegration(config));
    }
    if (config.integrations.yandex) {
        addIntegration('yandex', createYandexIntegration(config));
    }
    if (config.integrations.intent) {
        addIntegration('intent', createIntentIntegration());
    }
    if (config.integrations.naver) {
        addIntegration('naver', createNaverIntegration(config));
    }
    if (config.integrations.kakaoKeywordAds) {
        addIntegration('kakaoKeywordAds', createKakaoKeywordAdsIntegration(config));
    }
    if (config.integrations.kakaoMomentum) {
        addIntegration('kakaoMomentum', createKakaoMomentumIntegration(config));
    }
    Object.values(integrations).forEach((integration) => {
        integration.load();
    });
}
export { integrations, initIntegrations };
