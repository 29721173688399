/**
 * The application configuration with specified integrations and groups.
 * @type {Config}
 */
export const config_staging_am = {
    stand: 'staging',
    host: 'gfn-test.am',
    data: {},
    integrations: {
        log_sink: {
            dataset: 'https://europe-west3-gfn-am-data.cloudfunctions.net/log_sink?',
        },
        gtm: {
            gtm_id: '',
        },
        gtag: {
            tag_id: 'G-NNC6CMQ7VP',
        },
        facebook: {
            id: '478449647845667',
        },
        yandex: {
            ym_id: 95871671,
        },
        intent: {},
    },
    groups: {
        default: ['yandex'],
    },
    widgets: {
        chatra: {
            chatra_id: '5xDq5jWkEudTzQvvd',
            display: ['landing', 'lk'],
        },
    },
};
