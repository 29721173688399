/**
 * Custom hook to determine the current site part based on the URL.
 * @param config - The configuration object.
 * @returns An object containing the current site part and related flags.
 */
export function useSitePart(config) {
    // Get the current hostname and pathname from the window object
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;
    // Determine if the current page is the landing page
    const isLanding = (hostname === config.host || hostname === 'localhost') &&
        !pathname.startsWith('/app/');
    // Determine if the current page is the "lk" page
    const isLk = pathname.startsWith('/app/');
    // Determine if the current page is the KeyCloak page
    const isKeyCloak = hostname.startsWith('oauth.');
    // Determine the site part based on the above conditions
    const sitePart = isLanding
        ? 'landing'
        : isLk
            ? 'lk'
            : isKeyCloak
                ? 'keycloak'
                : null;
    // Return an object containing the site part and related flags
    return {
        isLanding,
        isLk,
        isKeyCloak,
        sitePart,
    };
}
