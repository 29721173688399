import { createChatraWidget } from './chatra';
const widgets = {};
function addWidget(name, widget) {
    widgets[name] = widget;
}
function initWidgets(config, sitePart) {
    if (config.widgets.chatra &&
        config.widgets.chatra.display.includes(sitePart)) {
        addWidget('chatra', createChatraWidget(config.widgets.chatra.chatra_id));
    }
    Object.values(widgets).forEach((widget) => {
        widget.load();
    });
}
export { initWidgets };
