import { loadScript } from '../utils';
import { event_schema } from '../events';
const transformEventProperties = (eventProperties) => {
    if (!eventProperties) {
        return;
    }
    let totalQuantity = 0;
    let totalPrice = 0;
    const products = [];
    for (const item of eventProperties.items) {
        totalQuantity += item.quantity;
        totalPrice += item.price;
        products.push({
            id: item.item_id,
            name: item.item_name,
            quantity: item.quantity.toString(),
            price: item.price.toString(),
        });
    }
    const transformedProperties = {
        total_quantity: totalQuantity.toString(),
        total_price: totalPrice.toString(),
        currency: eventProperties.currency,
        products: products,
    };
    return transformedProperties;
};
export const createKakaoKeywordAdsIntegration = (config) => {
    const load = () => {
        loadScript('//t1.daumcdn.net/kas/static/kp.js');
    };
    const sendEvent = (eventName, eventProperties) => {
        var _a, _b, _c, _d;
        const kakaoEventName = (_b = (_a = event_schema[eventName]) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.kakaoKeywordAds;
        const kakaoId = (_d = (_c = config === null || config === void 0 ? void 0 : config.integrations) === null || _c === void 0 ? void 0 : _c.kakaoKeywordAds) === null || _d === void 0 ? void 0 : _d.id;
        if (!kakaoEventName) {
            return;
        }
        if (eventName === 'purchase') {
            const transformedProperties = transformEventProperties(eventProperties);
            kakaoPixel(kakaoId)[kakaoEventName](transformedProperties);
        }
        else {
            kakaoPixel(kakaoId)[kakaoEventName](eventProperties);
        }
    };
    const isLoaded = () => {
        return typeof window.kakaoPixel === 'function';
    };
    return {
        load,
        sendEvent,
        isLoaded,
    };
};
