import { event_schema } from './schema';
import { sendEvent } from './send';
function handleClickTrigger(send, sitePart, selector, eventKey) {
    document.addEventListener('click', (event) => {
        const target = event.target;
        let targetElement = target.closest(selector);
        if (!targetElement)
            return;
        switch (eventKey) {
            case 'dbs_click':
                targetElement = target.closest('[data-dbs]');
                const dbsValue = targetElement === null || targetElement === void 0 ? void 0 : targetElement.getAttribute('data-dbs');
                send(eventKey, { dbs: dbsValue });
                break;
            default:
                send(eventKey);
                break;
        }
    });
}
function handleViewTrigger(send, sitePart, target, eventKey) {
    if (sitePart !== null && target.includes(sitePart)) {
        send(eventKey);
    }
}
function initEventListeners(schema, send, sitePart) {
    Object.entries(schema).forEach(([eventKey, { trigger }]) => {
        if (!trigger || Object.keys(trigger).length === 0) {
            return;
        }
        switch (trigger.type) {
            case 'pageview':
                if (window.location.pathname === trigger.selector) {
                    send(eventKey);
                }
                else if (trigger.target) {
                    handleViewTrigger(send, sitePart, trigger.target, eventKey);
                }
                break;
            case 'click':
                if (trigger.selector) {
                    handleClickTrigger(send, sitePart, trigger.selector, eventKey);
                }
                break;
        }
    });
}
export { initEventListeners, event_schema, sendEvent };
