import * as configs from '../config';
/**
 * The current value of the DOMAIN_ENV environment variable.
 * @type {string | undefined}
 */
const domainEnv = process.env.DOMAIN_ENV;
/**
 * The current value of the GFN_ENVIRONMENT environment variable.
 * @type {string | undefined}
 */
const standEnv = process.env.GFN_ENVIRONMENT;
if (!domainEnv || !standEnv) {
    throw new Error('The DOMAIN_ENV and GFN_ENVIRONMENT environment variables must be set.');
}
/**
 * The configuration object based on the current environment variables.
 * @type {Config}
 */
export const config = configs[`config_${standEnv}_${domainEnv}`];
/**
 * Checks if the application is running in development mode.
 * @type {boolean}
 */
export const isDev = /(-test.|localhost)/.test(window.location.hostname);
